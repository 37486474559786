.container-centered {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#loading-spinner {
  position: fixed;
  bottom: calc(50vh - 32px);
  left: calc(50vw - 32px);
  z-index: 10070;
  /*display: none;*/
}

[x-cloak] {
  display: none !important;
}
